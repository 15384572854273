import React from 'react';
import { useTranslation } from 'react-i18next';

function UploadProgress({ progress = 0 }) {
  const { t } = useTranslation(['common']);

  return (
    <div className="progress-view">
      <div className="label">{t('common:sending')}...</div>
      <div className="progress-bar">
        <div
          className="progress-indicator"
          style={{
            width: `${progress}%`
          }}
        ></div>
      </div>
    </div>
  );
}

export default UploadProgress;
