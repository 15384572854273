import React from 'react';

const AnimatedCheckmark = () => {
  return (
    <div className="animated-checkmark">
      <div className="circle">
        <div className="checkmark"></div>
      </div>
    </div>
  );
};

export default AnimatedCheckmark;
