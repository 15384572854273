import { useDispatch, useSelector } from 'react-redux';
import { APP_TOGGLE_LANGUAGE } from '../store/actions/app';
import i18n from './../i18n';
import { initReactI18next } from 'react-i18next';
import moment from 'moment-timezone';

export const useLanguageLoader = () => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.app.language);

  const switchLang = lang => {
    localStorage.setItem('lng', lang);
    i18n.use(initReactI18next).init({ lng: lang });
    moment.locale(lang);
  };

  return {
    language,
    toggleFrench: () => {
      switchLang('fr');
      dispatch({
        type: APP_TOGGLE_LANGUAGE,
        language: 'fr'
      });
    },
    toggleEnglish: () => {
      switchLang('en');
      dispatch({
        type: APP_TOGGLE_LANGUAGE,
        language: 'en'
      });
    }
  };
};
