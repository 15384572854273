import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import frLocales from './locales/fr';
import enLocales from './locales/en';

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false
  },
  debug: true,
  ns: ['common'],
  defaultNS: 'common',
  lng: localStorage.getItem('lng') || 'fr',
  resources: {
    fr: frLocales,
    en: enLocales
  }
});

export default i18n;
